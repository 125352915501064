import { CalculatorType } from "../@types/user";

import getFieldKey from "./getFieldKey";

class Calculator{

    getCalculatorfields({type}:{type:number}):CalculatorType{
        return [
            {
                name:"Job & Tool Specification",
                cmp:false,
                child:[
                    {
                        id: getFieldKey.project_description,
                        name:`${type === 0 ? "Project" : "Scenario"} Description`,
                        type:"text",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            Input the program or platform name, part or product used and recognised within your organization, and desired evaluation process.
                            </p>

                            <p>
                            Eg: “Chevy Corvette door trim panel Kaizen event.”
                            </p>
                            `
                        
                    },
                    {
                        id:getFieldKey.plant_number,
                        name:"Part/Job/Plant Number",
                        type:"text",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            The internally recognized part/product number goes into this input field.
                            </p>

                            <p>
                            Eg: “FD208-47KW”
                            </p>
                            `
                        ,
                        required:true
                    },
                    {
                        id:getFieldKey.no_of_parts,
                        name:"Number of Parts/Programs",
                        type:"nestedNum",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            In this field, we reference the total number of parts in the value-stream that creates a set and/or the number of total cavities in the primary tool.
                            </p>

                            <p>
                            Eg1: Primary Mold “ABC” is a 4-cavity mold that produces all 4 door trim panels  (driver front, passenger front, rear left, rear right) 
                            that creates the entire ABC door trim panel value stream. In this example, we can evaluate anywhere between 1 to 4 cavities; 
                            however, to evaluate the entire 4-cavity value-stream, 
                            we would enter “4” into this field to evaluate all 4 door trim panels as a set.
                            </p>
                            
                            <p>
                            Eg2: Similar to example 1 (above) If a value-stream is made up of multiple primary purchased components to create a set, 
                            then you would enter the desired number of primary components you would want to evaluate in that set. 
                            For example, if there are 2 primary components that creates a value-stream set and you want to evaluate the entire value-stream, 
                            you would insert a “2” into this field.
                            </p>
                            `
                        ,
                        required:true,
                        popupArrayName:"Cavity",
                        fields:[
                            {name:"Sell Price",type:"Cnumber",currency:true},
                            {name:"BOM Cost",type:"Cnumber",currency:true}
                        ],
                        entries:[]
                    },
                    {
                        id:getFieldKey.symmetrical,
                        name:"Are the cavities/sets symmetrical/identical?",
                        type:"bool",
                        value:"yes",
                        error:false,
                        msg:`
                            <p>
                            In this field, if the cavities are symmetrical or mirror images, have the same selling price, 
                            have the same work content, and have the same BOM cost; then you can select “Yes”. Otherwise, 
                            select “No” where you will be directed to an input screen where you will fill out each parts’ individual sell price and BOM.
                            </p>
                            `
                        ,
                        required:true,
                        disabled:true
                    }  
                ],
                pageErr:false
            },
            {
                name : "Sales, Volume and Material Information",
                cmp:false,
                child:[
                    {
                        id:getFieldKey.sell_price,
                        name:"Average Sell Price Per Part($/part)",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            If you entered “Yes” in the previous step, please input the common selling price for the identical cavities; otherwise, 
                            this field would be automatically calculated if you entered “No” after filling out the required information regarding each cavity.
                            </p>
                            `,
                        required : true,
                        currency:true
                    },
                    {
                        id:getFieldKey.bom_price,
                        name:"Average BOM Per Part ($/part)",
                        type:"num",
                        value:"",
                        error:false,
                        msg:
                            `
                            <p>
                            If you entered “Yes” in the previous step, 
                            please input the common BOM for the identical cavities; otherwise, 
                            this field would be automatically calculated if you entered “No” after filling out the required information regarding each cavity.
                            </p>
                            `
                            ,
                        required : true,
                        currency:true
                    },
                    {
                        id:getFieldKey.volume,
                        name:"Annual Sets/Volume per year",
                        type:"num",
                        value:"",
                        error:false,
                        msg:
                            `<p>
                            This field enters the estimated number of Annual Shots/Sets that should be run to achieve the overall projected annual volume, 
                            for example, if the annual part volume is 500,000 units for a symmetrical 4-cavity tool, 
                            then the annual projected shots would be 125,000 shots or 500,000 parts divided by 4 cavities. 
                            However, if the cavities are not symmetrical (eg. 4-part door trim panel example) 
                            if the required annual door trim set volume is 200,000 sets then 200,000 shots must be run off the 4-cavity tool to meet overall 
                            production volume.
                            </p>`
                            ,
                        required:true
                    },
                    {
                        id:getFieldKey.upper_capacity,
                        name:"Is there an upper Capacity Planning Volume limit?",
                        type:"decisive",
                        value:"",
                        error:false,
                        msg:
                            `<p>
                            In this field, only answer “Yes” if a contractual upper capacity limit agreement exists 
                            that once exceeded changes the commercial agreement terms and/or pricing. 
                            The default in this input field is “No”. If you answered “Yes”, please insert the upper capacity volume limit.
                            </p>
                            `
                        
                    },
                    {
                        id:getFieldKey.lower_capacity,
                        name:"Is there a lower Capacity Planning Volume limit?",
                        type:"decisive",
                        value:"",
                        error:false,
                        msg:`<p>
                                In this field, only answer “Yes” if a contractual lower capacity limit agreement exists 
                                that once exceeded changes the commercial agreement terms and/or pricing. 
                                The default in this input field is “No”. If you answered “Yes”, please insert the lower capacity volume limit.
                            </p>
                        `
                        
                    },                     
                    {
                        id:getFieldKey.months_remaining,
                        name:"Months Remaining in Job",
                        type:"num",
                        value:"60",
                        error:false,
                        msg:
                            `<p>
                                The anticipated remaining time in months that the Job will continue under the negotiated contract. 
                                This time-period will also serve as the primary NPV time horizon.
                            </p>
                            `
                        ,
                        required:true
                    },
                    {
                        id:getFieldKey.cost_of_capital,
                        name:"Weighted Average Cost of Capital %",
                        type:"num",
                        value:"10",
                        error:false,
                        msg:`
                            <p>
                            Weighted average cost of capital (WACC) represents a company's average after-tax cost of capital from all sources, 
                            including common stock, preferred stock, bonds, and other forms of debt. 
                            As such, WACC is the average rate that a company expects to pay to finance its business.
                            </p>
                            `
                        ,
                        percent:true
                    },
                    {
                        id:getFieldKey.tax_rate,
                        name:"Total Tax Rate %",
                        type:"num",
                        value:"35",
                        error:false,
                        msg:`
                            <p>
                            Total tax rate measures the amount of taxes and mandatory contributions payable by businesses after accounting for 
                            allowable deductions and exemptions as a share of commercial profits.
                            </p>
                            `
                        ,
                        required:true,
                        percent:true
                    },
                    {
                        id:getFieldKey.profit_loss,
                        name:"Enter Previous Year's Profit/Loss",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            If available, please enter the Enterprise Profit and/or Loss over the previous or trailing 12 months (TTM).
                            </p>

                            <p>
                            If the TTM is not available, please input the Enterprise Profit or Loss from the previous calendar year.
                            </p>

                            <p>
                            If the profit or loss information is more than 12 months old, leave this field blank.
                            </p>
                            `
                        ,
                        secDrop:{
                            list:[
                                {value:11,label:"Profit"},
                                {value:12,label:"Loss"}
                            ],
                            value:11
                        },
                        currency:true
                    },
                    {
                        id:getFieldKey.inflation,
                        name:"Do you want to include Inflation Rate(%) ?",
                        type:"decisive",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            If you choose “No”, then in the output no inflationary effects will be considered; 
                            if “Yes”, put the most accurate inflation rate that will affect the cost of your enterprise over the next calendar year.
                            </p>
                            `
                        ,
                        percent:true
                    },
                    {
                        id:getFieldKey.irr_hurdle,
                        name:"Do you have an IRR Hurdle Rate(%) ?",
                        type:"decisive",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            The hurdle rate is the minimum rate of return on an investment that will offset its costs. 
                            The internal rate of return is the amount above the break-even point that an investment may earn. 
                            A favorable decision on a project can be expected only if the internal rate of return is equal to or above the hurdle rate. 
                            In the output fields, if this percentage is achieved, it will be highlighted in green; if it is not achieved, 
                            it will be highlighted in red.
                            </p>
                            `
                        ,
                        percent:true
                    },
                    {
                        id:getFieldKey.atros_rate,
                        name:"Do you have an ATROS Hurdle Rate(%) ?",
                        type:"decisive",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            The ATROS Hurdle Rate is the minimum acceptable percentage of after-tax profit divided by the total sales of the evaluated project 
                            that the company will approve. For example, 
                            if the hurdle rate is 8% and a product with $1M annual sales achieves a total annual profit of  $60,000, 
                            then the ATROS would be only 6% and fail the hurdle rate. In this example, this ATROS result will be highlighted in red in the output 
                            fields. However, if the results met or exceeded 8%, it would be highlighted in green.
                            </p>
                            `
                        ,
                        percent:true
                    },
                    {
                        id:getFieldKey.scrap_sales,
                        name:"Scrap recovery sales",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This is the periodic (weekly, monthly, yearly) scrap reclaim recovery value.
                            </p>
                            `
                        ,
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        id:getFieldKey.beginning_fiscal,
                        name:"Beginning of fiscal year ? (Date & Month)",
                        type:"num",
                        value:"1",
                        error:false,
                        msg:`
                            <p>
                            Enter the month of your company/corporation’s fiscal year.
                            </p>
                        `
                        ,
                        secDrop:{
                            list:[
                                {value:0,label:"Jan"},
                                {value:1,label:"Feb"},
                                {value:2,label:"Mar"},
                                {value:3,label:"Apr"},
                                {value:4,label:"May"},
                                {value:5,label:"Jun"},
                                {value:6,label:"Jul"},
                                {value:7,label:"Aug"},
                                {value:8,label:"Sep"},
                                {value:9,label:"Oct"},
                                {value:10,label:"Nov"},
                                {value:11,label:"Dec"}
                            ],
                            value:0
                        }
                    }
                ],
                pageErr:false
    
            },
            {
                name:"Machine and Operator Burden Summary",
                cmp:false,
                child:[
                    {
                        id: getFieldKey.overhead_rate,
                        name:`Standard Cost Overhead Rate ($/hr)`,
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            The overhead rate, sometimes called the standard overhead rate, 
                            is the cost a business allocates to production to get a more complete picture of product and service costs. 
                            The overhead rate is calculated by adding indirect costs and then dividing those costs by a specific measurement.
                            </p>

                            <p>
                            Please check to ensure whether the Standard Cost Overhead Rate does or does not include machine burden rate and/or SG&A rates. 
                            If the Standard Cost Overhead Rate does include machine burden and/or SG&A, there is no need to fill out the two fields below. However, 
                            if those costs are not included in the Standard Cose Overhead Rate, you must also include a value for the machine burden rate and/or SG&A rates.
                            </p>
                            `
                        ,
                        required:true,
                        currency:true
                    },
                    {
                        id: getFieldKey.machine_burden_rate,
                        name:"Machine Burden Rate ($/hr)",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            In manufacturing, machine burden is often applied to inventory cost in addition to Standard Overhead Costs to arrive at an 
                            estimated Total Overhead cost for an activity center, value-stream, entity or even enterprise level organization. 
                            Machine Burden Rate is often referred to as factory or manufacturing overhead directly associated with capital equipment or 
                            machine replacement costs, which can also include machine maintenance, 
                            critical spare cost and machine related utilities consumption costs. 
                            This field should be filled if it is not included in the Standard Cost Overhead Rate.
                            </p>
                            `
                        ,
                        currency:true,
                        required:true
                    },
                    {
                        id:getFieldKey.sga_marketing_cost,
                        name:"SG&A, ED&T, Launch, R&D, Marketing and Advertising ($/hr)",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            Selling, general, and administrative expenses (SG&A) include all non-production expenses for a reporting period. 
                            Examples of these expenses are marketing, advertising, rent, and utilities. 
                            This line item includes nearly all business costs that aren't directly attributable to making a product or performing a service. 
                            This field should be filled if it is not included in the Standard Cost Overhead Rate.
                            </p>
                            `
                        ,
                        currency:true
                    }
                ],
                pageErr:false
            },
            {
                name:"STD Direct Labor, Break Relief, & Wage Operator Summary",
                cmp:false,
                child:[
                    {
                        id: getFieldKey.operator_continuous,
                        name:"Number of Operators in Continuous Operation",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            Direct Labour is any worker that helps move a product or service from a lower state of raw materials and WIP closer to a state of a 
                            finished good or a completed service. In this field, 
                            please record the total number of direct labour for the activity, process, value-stream or enterprise being evaluated. 
                            In this field, do not include break relief operators as that will be calculated in the next field.
                            </p>
                            `
                        ,
                        required:true
                    },
                    {
                        id: getFieldKey.operator_nc,
                        name:"Number of Operators Break Relieving",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            Direct Labour is any worker that helps move a product or service from a lower state of raw materials and WIP closer to a state of a 
                            finished good or a completed service. In this field, 
                            please record the total number of direct labour for the activity, process, value-stream or enterprise being evaluated. 
                            In this field, do not include break relief operators as that will be calculated in the next field.
                            </p>
                            `
                        ,
                        required:true
                    },
                    {
                        id: getFieldKey.break_relief,
                        name:"Break Relief Ratio %",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            In this field, please enter a number of break relief operators divided by the total primary operators in the operation being evaluated.
                            </p>

                            <p>
                            E.g.: If a break relief operator relieves 4 primary operators during the work shift, 
                            the percentage would be ¼ or 25%; whereas, 
                            if a break relief operator relieves 4 primary operators during the work shift, the percentage would be 1/5 or 20%.
                            </p>
                        `,
                        required:true,
                        percent:true
                    },
                    {
                        id: getFieldKey.total_break,
                        name:"Total Break/Minutes/Shift/ per DL",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `As the title says, this is the total number of cumulative minutes for both breaks and lunch for the shift.`
                        ],
                        required:true
                    },
                    {
                        id: getFieldKey.base_wage,
                        name:"Average Operator Base Wage $/hr",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This is simply the average direct labour hourly base wage for the operation being evaluated.
                            </p>
                            `
                        ,
                        currency:true,
                        required:true
                    },
                    {
                        id: getFieldKey.benefit_ratio,
                        name:"Hourly Employee Benefit Ratio %",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This would be the average benefit percentage to include healthcare, 
                            vacation & retirement benefits plus deducted social security, FICA, medicare & Medicaid, 
                            and/or any other benefit included above the base wage for the direct laborer.
                            </p>

                            <p>
                            The benefit ratio is the benefits accrued over a standard 40-hour work week or whatever the recognized average work week is for the direct labor pool.
                            </p>

                            <p>
                            E.g.: If the total weekly benefits for any direct labor is $280 and the standard work week is 40 hours, 
                            then the benefits per hour would be $280/40 hours or $7/hour: and if, the hourly base rate wage is $20/hour, 
                            then benefit percentage will be ($7/hour) / ($20/hour) or 35%. Thus, in this scenario, you would enter 35%.
                            </p>
                        `,
                        percent:true
                    },
                    {
                        id: getFieldKey.fringed_wage,
                        name:"Average Operator Fully Fringed Wage $/hr",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This field is auto-calculated, and the default percentage is 35%. 
                            This will be a result of the percentage benefit entered in the field above and is simply the base wage plus the percentage benefit 
                            times the base wage.
                            </p>
                            `
                        ,
                        currency:true,
                        required:true,
                        disabled: true
                    },
                    {
                        id: getFieldKey.otp_perc,
                        name:"Overtime Premium Percentage",
                        type:"popup",
                        value:"",
                        error:false,
                        msg:`
                        <p>
                        This indicates the overtime premium percentage once you have exceeded the weekly standard work hours. It will record the overtime percentages in addition to the base wage for Saturdays, Sundays and Holidays. These percentages being entered represents the overtime premium in addition to the base wage, e.g.: if the Saturday overtime percentage is 50% premium and the base wage is $20/hour, then the overtime wage will be ($20/hour) + ($20/hour * 50%), or $30/hour.
                        </p>
                        `
                        ,
                        data:[
                            {
                                name:"Saturdays",
                                value:"",
                                percent:true
                            },
                            {
                                name:"Sundays",
                                value:"",
                                percent:true
                            },
                            {
                                name:"Holidays",
                                value:"",
                                percent:true
                            }
                        ],
                        required:true

                    },
                    {
                        id: getFieldKey.abs_and_turn,
                        name:"Absenteeism & Turnover",
                        type:"oneScreenPopup",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This records your average weekly planned and unplanned absenteeism and also your average weekly turnover as a percent of the total workforce within each workforce category to include direct labor, variable indirect labor, and fixed indirect labor.
                            </p>
                            <p>
                            E.g.: If the company has a total direct labor pool of 300 workers and 10 are taking planned vacation and another 5 are unplanned absences, you would enter 3.33% into the planned absenteeism field which is equal to 10/300 and you would enter 1.67% into the unplanned absenteeism field which is equal to 5/300.
                            </p>
                            `
                        ,
                        dataArr:[
                            {
                                columnName:"Absenteeism",
                                data:[
                                    {
                                        name:"Planned weekly absenteeism",
                                        type:"Cnumber2",
                                        value:"",
                                        perc:true,
                                        required:true
                                    },
                                    {
                                        name:"Unplanned weekly absenteeism",
                                        type:"Cnumber2",
                                        value:"",
                                        perc:true,
                                        required:true
                                    }
                                ]
                            },
                            {
                                columnName:"Turnover",
                                data:[
                                    {
                                        name:"Weekly turnover rate",
                                        type:"Cnumber2",
                                        value:"",
                                        perc:true,
                                        required:true
                                    }
                                ]
                            }
                        ],
                        required:true

                    }
                ],
                pageErr:false
            },
            {
                name:"ABC Direct Labor, Break Relief, & Wage Operator Summary",
                cmp:false,
                child:[
                    {
                        id: getFieldKey.abc_operator_continuous,
                        name:"Number of Operators in Continuous Operation",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            Direct Labour is any worker that helps move a product or service from a lower state of raw materials and WIP closer to a state of a 
                            finished good or a completed service. In this field, 
                            please record the total number of direct labour for the activity, process, value-stream or enterprise being evaluated. 
                            In this field, do not include break relief operators as that will be calculated in the next field.
                            </p>
                            `
                        ,
                        required:true
                    },
                    {
                        id: getFieldKey.abc_operator_nc,
                        name:"Number of Operators Break Relieving",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            Direct Labour is any worker that helps move a product or service from a lower state of raw materials and WIP closer to a state of a 
                            finished good or a completed service. In this field, 
                            please record the total number of direct labour for the activity, process, value-stream or enterprise being evaluated. 
                            In this field, do not include break relief operators as that will be calculated in the next field.
                            </p>
                            `
                        ,
                        required:true
                    },
                    {
                        id: getFieldKey.abc_break_relief,
                        name:"Break Relief Ratio %",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            In this field, please enter a number of break relief operators divided by the total primary operators in the operation being evaluated.
                            </p>

                            <p>
                            E.g.: If a break relief operator relieves 4 primary operators during the work shift, 
                            the percentage would be ¼ or 25%; whereas, 
                            if a break relief operator relieves 4 primary operators during the work shift, the percentage would be 1/5 or 20%.
                            </p>
                        `,
                        required:true,
                        percent:true
                    },
                    {
                        id: getFieldKey.abc_total_break,
                        name:"Total Break/Minutes/Shift/ per DL",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `As the title says, this is the total number of cumulative minutes for both breaks and lunch for the shift.`
                        ],
                        required:true
                    },
                    {
                        id: getFieldKey.abc_base_wage,
                        name:"Average Operator Base Wage $/hr",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This is simply the average direct labour hourly base wage for the operation being evaluated.
                            </p>
                            `
                        ,
                        currency:true,
                        required:true
                    },
                    {
                        id: getFieldKey.abc_benefit_ratio,
                        name:"Hourly Employee Benefit Ratio %",
                        type:"num",
                        value:"",
                        error:false,
                        msg:
                            `
                            <p>
                            This would be the average benefit percentage to include healthcare, vacation & retirement  benefits plus deducted social security, FICA, medicare & medicaid and/or any other benefit included above the base wage for the direct laborer.
                            </p>
                            <p>
                            The benefit ratio is the benefits accrued over a standard 40-hour work week or whatever the recognised average work week is for the direct labor pool.
                            </p>
                            <p>
                            E.g.: If the total weekly benefits for any direct labor is $280 and the standard work week is 40-hours, then the benefits per hour would be $280/40 hours or $7/hour: and if, the hourly base rate wage is $20/hour, then benefit percentage will be ($7/hour) / ($20/hour) or 35%. Thus, in this scenario you would enter 35%.
                            </p>
                            `
                        ,
                        percent:true
                    },
                    {
                        id: getFieldKey.abc_fringed_wage,
                        name:"Average Operator Fully Fringed Wage $/hr",
                        type:"num",
                        value:"",
                        error:false,
                        msg:
                            `
                            <p>
                            This field is auto-calculated, and the default percentage is 35%. This will be a result of the percentage benefit entered in the field above and is simply the base wage plus the percentage benefit times the base wage.
                            </p>
                            `
                        ,
                        currency:true,
                        required:true,
                        disabled: true
                    },
                    {
                        id: getFieldKey.abc_otp_perc,
                        name:"Overtime Premium Percentage",
                        type:"popup",
                        value:"",
                        error:false,
                        msg:`
                        <p>
                        This indicates the overtime premium percentage once you have exceeded the weekly standard work hours. It will record the overtime percentages in addition to the base wage for Saturdays, Sundays and Holidays. These percentages being entered represents the overtime premium in addition to the base wage, e.g.: if the Saturday overtime percentage is 50% premium and the base wage is $20/hour, then the overtime wage will be ($20/hour) + ($20/hour * 50%), or $30/hour.
                        </p>
                            `
                        ,
                        data:[
                            {
                                name:"Saturdays",
                                value:"",
                                percent:true
                            },
                            {
                                name:"Sundays",
                                value:"",
                                percent:true
                            },
                            {
                                name:"Holidays",
                                value:"",
                                percent:true
                            }
                        ],
                        required:true

                    },
                    {
                        id: getFieldKey.abc_abs_and_turn,
                        name:"Absenteeism & Turnover",
                        type:"oneScreenPopup",
                        value:"",
                        error:false,
                        msg:
                            `
                            <p>
                            This records your average weekly planned and unplanned absenteeism and also your average weekly turnover as a percent of the total workforce within each workforce category to include direct labor, variable indirect labor, and fixed indirect labor.
                            </p>
                            <p>
                            E.g.: If the company has a total direct labor pool of 300 workers and 10 are taking planned vacation and another 5 are unplanned absences, you would enter 3.33% into the planned absenteeism field which is equal to 10/300 and you would enter 1.67% into the unplanned absenteeism field which is equal to 5/300.
                            </p>
                            `
                        ,
                        dataArr:[
                            {
                                columnName:"Absenteeism",
                                data:[
                                    {
                                        name:"Planned weekly absenteeism",
                                        type:"Cnumber2",
                                        value:"",
                                        perc:true,
                                        required:true
                                    },
                                    {
                                        name:"Unplanned weekly absenteeism",
                                        type:"Cnumber2",
                                        value:"",
                                        perc:true,
                                        required:true
                                    }
                                ]
                            },
                            {
                                columnName:"Turnover",
                                data:[
                                    {
                                        name:"Weekly turnover rate",
                                        type:"Cnumber2",
                                        value:"",
                                        perc:true,
                                        required:true
                                    }
                                ]
                            }
                        ],
                        required:true

                    }
                ],
                pageErr:false
            },
            {
                name:"Operations Overview",
                cmp:false,
                child:[
                    {
                        id: getFieldKey.downtime,
                        name:"Downtime",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            Downtime, also known as Stop Time, is all time where the manufacturing process was intended to be running but was not due to Unplanned Stops (e.g., breakdowns), or Planned Stops (e.g., changeovers). Downtime is entered as a percentage of total downtime divided by total plant runtime.
                            </p>
                            `
                    
                    },
                    {
                        id: getFieldKey.oee,
                        name:"OEE",
                        type:"num",
                        value:"",
                        error:false,
                        msg:
                            `
                            <p>
                            OEE is a metric which helps us understand how well a line/cell/machine is performing compared to design, by comparing actual production output to possible production output. Labor is not directly measured as part of OEE.
                            </p>
                            <p>
                            Overall Equipment Effectiveness (OEE) is an industry standard, versatile measurement for production efficiency. It suits any industry or process. OEE is the result of three factors, Availability, Performance and Quality, which describe the six big losses. Together these factors form OEE (%) = Availability (%) X Performance (%) X Quality Rate (%).
                            </p>
                            `
                        ,
                        required:true,
                        percent:true
                    },
                    {
                        id: getFieldKey.scrap,
                        name:"Scrap",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            Scrap is a percentage of the total number of defective parts ran divided by the total number of parts ran. E.g.: The operation ran 100 total parts with 94 parts being good and 6 parts being scrap; therefore, the total scrap percentage equals 6% or 6 defective parts divided by 100 total parts ran.
                            </p>
                            `
                        ,
                        percent:true
                    },
                    {
                        id: getFieldKey.cycle_time,
                        name:"Cycle Time",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This is the standard quoted cycle time in seconds.
                            </p>
                            
                            `
                        ,
                        required:true
                    }
                ],
                pageErr:false
            },
            {
                name:"Operating Pattern",
                cmp:false,
                child:[
                    {
                        id: getFieldKey.shifts_day,
                        name:"How many shifts per day?",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            Please enter the total number of shifts per week. E.g.: If your organization three 8-hour shifts, 5 days a week, you would enter 3; however, if your organization runs four 12-hour shifts, 7 days per week, you would enter 4.
                            </p>
                            `
                        ,
                        required:true
                    },
                    {
                        id: getFieldKey.hours_shift,
                        name:"How many hours per shift?",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            How many planned hours per shift your organization runs.
                            </p>
                            `
                        ,
                        required:true
                    },
                    {
                        id: getFieldKey.days_week,
                        name:"How many days per week?",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            How many planned days per week your organization runs.
                            </p>
                            `
                        ,
                        required:true
                    },
                    {
                        id: getFieldKey.days_year,
                        name:"How many operating days per year?",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This field specifies the operational schedule, such as the number of operating days per year.
                            </p>
                        `
                        ,
                        required:true
                    },
                    {
                        id: getFieldKey.otp1,
                        name:"Overtime Premium 1 above X yearly hours?",
                        type:"num",
                        value:"",
                        error:false,
                        msg:
                            `
                            <p>
                            Based of your organization’s shifts’ operating patterns, please enter the number of hours during a calendar year where operations would move into overtime premium 1 then subsequently overtime premium 2.
                            </p>
                            <p>
                            E.g.: If your organization runs 2 8-hour shifts, 5 days a week at 250 operating days per year, then each shift would operate (40-hours per week) * (250 days per year), or 2000 hours per shift before moving into OT Premium 1. Thus, 2 shifts at 2000 hours per year would equal 4000 hours per year before entering OT Premium 1. OT Premium 2 would be reached at operations exceeding 4000 hours per year as each operating day per shift per year equals 400 hours or 8 hours per day times 50 operating weeks per year.
                            </p>
                            `
                        
                    },
                    {
                        id: getFieldKey.otp2,
                        name:"Overtime Premium 2 above Y yearly hours?",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            Similar to OT Premium 1, OT Premium 2 is when the operating hours exceeds the base plus OT Premium 1 hours annually. This generally applies to holiday work and Sunday double time work.
                            </p>
                            <p>
                            E.g.: If an organization is running 2 8-hour shifts, 250 operating days per year on a monday to friday schedule each operating shift per year equals 2000 hours per year or 8 hours per shift times 250 hours per year monday through friday. Therefore, each operating day equals 8 hours times 50 operating weeks per year, or 400 hours. In this example, base time per year equals 2000 hours shift per year times 2 shifts equalling 4000 base hours per year plus 800 OT Premium 1 hours per year resulting in OT Premium 2 for any hours exceeding 4800 hours per year.
                            </p>
                            `
                        
                    }
    
                ],
                pageErr:false
            },
            {
                name:"Activity Based Variance",
                cmp:false,
                child:[
                    {
                        id: getFieldKey.vl_added,
                        name:"How many Variable Indirect labour added?",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:
                            `
                            <p>
                            Any actual hourly Indirect resources added to satisfy incremental production requirements based off the scenario inputs.
                            </p>
                            `
                        ,
                        popupName:"Enter the number of Variable Indirect labour added!",
                        popupArrayName:"Variable Indirect Labour",
                        fields:[
                            {name:"Name of the Position",type:"string",required:true},
                            {name:"Fully Fringed Wage $/hr", type:"Cnumber",currency:true,required:true},
                            {name:"Base Wage $/hr", type:"Cnumber",currency:true,required:true},
                            {name:"% Allocation to Scenario", type:"Cnumber",percent:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        id: getFieldKey.vl_removed,
                        name:"How many Variable Indirect labour removed?",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            Any actual hourly Indirect resources removed to satisfy incremental production requirements based off the scenario inputs.
                            </p>
                            `
                        ,
                        popupName:"Enter the number of Variable Indirect labour removed!",
                        popupArrayName:"Variable Indirect Labour",
                        fields:[
                            {name:"Name of the Position",type:"string",required:true},
                            {name:"Fully Fringed Wage $/hr", type:"Cnumber",currency:true,required:true},
                            {name:"Base Wage $/hr", type:"Cnumber",currency:true,required:true},
                            {name:"% Allocation to Scenario", type:"Cnumber",percent:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        id: getFieldKey.fl_added,
                        name:"How many Fixed Indirect labour added?",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            Any actual salary Indirect resources added to satisfy incremental production requirements based off the scenario inputs.
                            </p>
                            `
                        ,
                        popupName:"Enter the number of Fixed Indirect labour added!",
                        popupArrayName:"Fixed Indirect Labour",
                        fields:[
                            {name:"Name of the Position",type:"string",required:true},
                            {name:"Fully Fringed Wage $/hr", type:"Cnumber",currency:true,required:true},
                            {name:"Base Wage $/hr", type:"Cnumber",currency:true,required:true},
                            {name:"% Allocation to Scenario", type:"Cnumber",percent:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        id: getFieldKey.fl_removed,
                        name:"How many Fixed Indirect labour removed?",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            Any actual salary Indirect resources removed to satisfy incremental production requirements based off the scenario inputs.
                            </p>
                            `
                        ,
                        popupName:"Enter the number of Fixed Indirect labour removed!",  
                        popupArrayName:"Fixed Indirect Labour",
                        fields:[
                            {name:"Name of the Position",type:"string",required:true},
                            {name:"Fully Fringed Wage $/hr", type:"Cnumber",currency:true,required:true},
                            {name:"Base Wage $/hr", type:"Cnumber",currency:true,required:true},
                            {name:"% Allocation to Scenario", type:"Cnumber",percent:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        id: getFieldKey.alloc_shared,
                        name:"Allocation for shared resources",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This is to apply an appropriate portion of the contribution margin to share resources such as plant, materials, 
                            engineering, quality managers, etc. This allocation generally is allocated as a percent of project revenue over 
                            total revenue to account for the shared resources regarding the plant’s fixed indirect top management.
                            </p>
                        `,
                        popupName:"Enter the number of shared resources!",  
                        popupArrayName:"Shared resource",
                        fields:[
                            {name:"Name of the Position",type:"string",required:true},
                            {name:"Fully Fringed Wage $/hr", type:"Cnumber",currency:true,required:true},
                            {name:"Base Wage $/hr", type:"Cnumber",currency:true,required:true},
                            {name:"% Allocation to Scenario", type:"Cnumber",percent:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        id: getFieldKey.sga_added,
                        name:"How many Plant SG&A resources added?",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This field includes any sales, general management and/or administrative salaries or costs added and 
                            also that are not accounted for in the general plant staff salaried management team; however, 
                            still is allocated at the plant level.
                            </p>
                        
                        `
                        ,
                        popupName:"Enter the number of SG&A resources added",  
                        popupArrayName:"G&A resource",
                        fields:[
                            {name:"Name of the Position",type:"string",required:true},
                            {name:"Fully Fringed Wage $/hr", type:"Cnumber",currency:true,required:true},
                            {name:"Base Wage $/hr", type:"Cnumber",currency:true,required:true},
                            {name:"% Allocation to Scenario", type:"Cnumber",percent:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        id: getFieldKey.sga_removed,
                        name:"How many Plant SG&A resources removed?",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This field includes any sales, general management and/or administrative salaries or costs removed 
                            and also that are not accounted for in the general plant staff salaried management team; however, 
                            still is allocated at the plant level.
                            </p>
                        
                        `
                        ,
                        popupName:"Enter the number of SG&A resources removed",  
                        popupArrayName:"G&A resource",
                        fields:[
                            {name:"Name of the Position",type:"string",required:true},
                            {name:"Fully Fringed Wage $/hr", type:"Cnumber",currency:true,required:true},
                            {name:"Base Wage $/hr", type:"Cnumber",currency:true,required:true},
                            {name:"% Allocation to Scenario", type:"Cnumber",percent:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        id: getFieldKey.utility_inc_red,
                        name:"Utilities Increase/Reduction",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            Any addition or reduction of overall utilities costs to satisfy incremental/reduced production requirements based off the scenario inputs.
                            </p>
                            `
                        ,
                        currency:true
                    },
                    {
                        id: getFieldKey.annual_plant_rent,
                        name:"Annual Plant Rent",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This field includes the summation of the following:
                            </p>
                            <ul>
                                <li>
                                Represents the annual cost for all rents for the plant and land.
                                </li>
                                <li>
                                Represents the annual cost for any major equipment rentals currently being paid.
                                </li>
                                <li>
                                Any other major annual rental cost not associated with the above bullets or accounted for in any other fields of the quantum leap program.
                                </li>
                            </ul>
                        `
                        ,
                        popupName:"How many different rents do you have?",
                        popupArrayName:"Rent type",
                        fields:[
                            {name:"Name of the Rent",type:"string",required:true},
                            {name:"Annual Cost", type:"Cnumber",currency:true,required:true},
                            {name:"% Allocation to Scenario", type:"Cnumber",percent:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        id: getFieldKey.ong_annual_testing,
                        name:"Ongoing Annual Testing",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This field represents the overhead and material cost for ongoing annual testing requirements; 
                            this would include the overhead and material cost for any ongoing destructive testing and the overhead 
                            cost of any non-destructive testing above and beyond normal testing performed by plant internal quality and 
                            engineering staff. This would also include any external third party testing costs.
                            </p>
                        `
                        ,
                        currency:true
                    },
                    {
                        id: getFieldKey.add_activity_line_added,
                        name:"Additional Activity-based Line Items required?",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            Any other non-accounted activity based costs to satisfy incremental/reduced production requirements based off the scenario inputs.
                            </p>
                            `
                        ,
                        popupName:"Enter the number of Activity-based Line Items!",  
                        popupArrayName:"Activity-based Item",
                        fields:[
                            {name:"Description",type:"string",required:true},
                            {name:"Annual Cost", type:"Cnumber",currency:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        id: getFieldKey.other_var_line_added,
                        name:"Number of Other Variable Line Items?",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This section is reserved for items such as incremental/reduced like wholesale equipment leasebacks, forklift leases, 
                            indirect purchases critical spares items increase or reduction, or any franchise fees etc.
                            </p>
                            `
                        ,
                        popupName:"Enter the number of Other Variable Line Items!",
                        popupArrayName:"Variable Item",
                        fields:[
                            {name:"Description",type:"string",required:true},
                            {name:"Annual Cost", type:"Cnumber",currency:true,required:true}
                        ],
                        entries:[]
                    }
                ],
                pageErr:false
            },
            {
                name:"Enterprise Activity Based Variance",
                cmp:false,
                child:[
                    {
                        id: getFieldKey.annual_sga_allocation,
                        name:"Annual SG&A Allocation",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This field represents the total activity-based cost allocated to the plant, program for activity center.
                            For example: If we are evaluating the entire operation and the operation revenue is $100 million and the entire 
                            enterprise revenue is $1 Billion, then the operation represents 10% of the enterprise revenue. 
                            Therefore, if the enterprise total SG&A is $10 million, the SG&A allocation would be $1 million or 10% of the total SG&A cost. 
                            The same logic holds true if evaluating smaller value-streams or activity centers within the operation. For example, 
                            if we are evaluating a $10 million value-stream  within the $100 million operation, 
                            the SG&A cost allocation for this value stream would be $100,000, because the value-stream represents 1% of the total enterprise 
                            revenue; therefore, 1% of the total $10 million SG&A equals $100,000.
                            </p>
                        `
                        ,
                        currency:true
                    },
                    {
                        id: getFieldKey.legal_pro_service,
                        name:"Legal & Professional Services",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This section represents any one-time and/or ongoing cost in the following categories:
                            </p>
                            <ul>
                                <li>
                                Legal Fees
                                </li>
                                <li>
                                Consulting Fees
                                </li>
                                <li>
                                Outsourced Professional Services
                                </li>
                                <li>
                                    BPO (Business Process Outsourcing). These include items such as:
                                    <ul>
                                        <li>
                                        RPO (Recruiting Process Outsourcing)
                                        </li>
                                        <li>
                                        OPO (Operational Process Outsourcing)
                                        </li>
                                        <li>
                                        Any third-party vendors eg. 3PL, Outsourced Operational Processes, Third-Party Sorting & Inspection, etc.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <p>
                            These costs are only to be entered if they are not included in the current bill of materials (BOM’s). 
                            However, if they are accounted for in the standard overhead cost, these costs must be included in this section as an 
                            activity-based cost.
                            </p>
                        `
                        ,
                        secDrop:{
                            list:[
                                {value:0,label:"one time"},
                                {value:1,label:"annual"},
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        id: getFieldKey.tech_service,
                        name:"Technical Services",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This section represents any one-time and/or ongoing cost in the following categories:
                            </p>
                            <ul>
                                <li>
                                Telephone or Telecommunications cost 
                                </li>
                                 <li>
                                Internet cost
                                </li>
                                 <li>
                                Technical Subscription Cost
                                </li>
                                 <li>
                                ERP Cost
                                </li>
                                 <li>
                                Any technical services cost e.g, Moldflow, FEM Analysis, external IT Cost, etc.
                                </li>
                                 <li>
                                Third-party MRO cost
                                </li>
                                 <li>
                                Etc
                                </li>
                            </ul>
                        `
                        ,
                        secDrop:{
                            list:[
                                {value:0,label:"one time"},
                                {value:1,label:"annual"},
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        id: getFieldKey.annual_marketing_cost,
                        name:"Annual Marketing & Advertising Cost",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This field represents the summation of all one-time and/or annual ongoing marketing and advertising cost.
                            </p>
                        `
                        ,
                        secDrop:{
                            list:[
                                {value:0,label:"one time"},
                                {value:1,label:"annual"},
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        id: getFieldKey.rnd,
                        name:"R&D",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This field represents the summation of all one-time and/or annual ongoing R&D cost.
                            </p>
                            `
                        ,
                        secDrop:{
                            list:[
                                {value:0,label:"one time"},
                                {value:1,label:"annual"},
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        id: getFieldKey.launch_cost,
                        name:"Launch Cost",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This field represents the total summation of all unaccounted for launch related costs incurred that are not captured and any of the other Quantum Leap fields.
                            </p>
                        `
                        ,
                        secDrop:{
                            list:[
                                {value:0,label:"one time"},
                                {value:1,label:"annual"},
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        id: getFieldKey.design_and_engineer_cost,
                        name:"Design and Engineering Cost",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This field represents any previously unaccounted for Design and Engineering Cost associated with the evaluated project.
                            </p>
                        `
                        ,
                        secDrop:{
                            list:[
                                {value:0,label:"one time"},
                                {value:1,label:"annual"},
                            ],
                            value:0
                        },
                        currency:true
                    }
                ],
                pageErr:false
            },
            {
                name:"Investment",
                cmp:false,
                child:[
                    {
                        id: getFieldKey.project_inv,
                        name:"Project Investment",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            Any Investment directly associated with the project’s scenario that can be expensed within the same calendar year.
                            </P>
                            <p>
                            E.g.: Consulting or professional fees, legal fees, restructuring fees, etc.
                            </p>
                            `
                        ,
                        currency:true
                    },
                    {
                        id: getFieldKey.capital_inv,
                        name:"Capital Investment",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            Records any incremental new or used capital assets that according to GAAP must adhere to a predetermined depreciation schedule.
                            </p>
                            <p>
                            E.g.: Major presses, equipments, robots, assembly lines, quality testers, etc.
                            </p>
                            `
                        ,
                        popupName:"How many equipments?",
                        popupArrayName:"Equipment",
                        fields:[
                            {name:"Description",type:"string",required:true},
                            {name:"Depreciation schedule",type:"Cnumber",required:true},
                            {name:"Cost", type:"Cnumber",currency:true,required:true},
                            {name:"Salvage Value", type:"Cnumber",currency:true},
                            {name:"What year in the life we will salvage", type:"Cnumber"}
                        ],
                        entries:[]
                    },
                    {
                        id: getFieldKey.installation_and_rigging,
                        name:"Installation Rigging Expenses",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This is simply fully expensible installation and/or rigging fees associated with capital equipments.
                            </p>
                            `
                        ,
                        currency:true
                    },
                    {
                        id: getFieldKey.any_other_expense,
                        name:"Any other Investment Expense",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This is any other investment expense not captured in the interviewed process.
                            </p>
                            `
                        ,
                        currency:true
                    }
                ],
                pageErr:false
            },    
            {
                name:"Off Standard Costing",
                cmp:false,
                child:[
                    {
                        id: getFieldKey.outbound_pre_fright,
                        name:"Outbound Premium Freight ",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This is total outbound premium freight cost paid by the organization logged either weekly, monthly or annually.
                            </p>
                            `
                        ,
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        id: getFieldKey.inbound_pre_fright,
                        name:"Inbound Premium Freight",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This is total inbound premium freight cost paid by the organization logged either weekly, monthly or annually.
                            </p>
                            `
                        ,
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        id: getFieldKey.quality_charge,
                        name:"Quality Charges",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This is total quality charges cost paid by the organization logged either weekly, monthly or annually. 
                            This charges include QRMA or any quality related costs due to quality rejects or quality related issues.
                            </p>
                            `
                        ,
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        id: getFieldKey.tp_sorting,
                        name:"Third Party Sorting Charges GP-12",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            These includes all customer directed internal or external sorting costs paid by the organization logged either weekly, monthly or annually.
                            </p>
                            `
                        ,
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        id: getFieldKey.internal_sorting,
                        name:"Internal Sorting Charges",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            All sorting and inspection costs internally directed by the organization or paid for the organization logged either weekly, monthly or annually.
                            </p>
                            `
                        ,
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        id: getFieldKey.offline_charge,
                        name:"Offline Charges",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            These are charge backed by the customers for creating a delay in customer production due to short shipments 
                            and/or quality defects created by the organization that create a situation where the customer is unable to 
                            manufacture complete units on time and also requires retro-fitting of the late or defective parts provided by the organization 
                            logged either weekly, monthly or annually.
                            </p>
                            `
                        ,
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        id: getFieldKey.shut_down_charge,
                        name:"Shut down Charges",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            Shut down charges are severe charges levied against the organization from the customer where the organization’s 
                            inability to ship on time or ship quality parts shuts down the production operation of the customer paid by the 
                            organization logged either weekly, monthly or annually.
                            </p>
                            `
                        ,
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        id: getFieldKey.any_other_offstd_cost,
                        name:"Any other Off standard Cost",
                        type:"num",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            Any other off standard cost not included in this list paid by the organization logged either weekly, monthly or annually.
                            </p>
                            `
                        ,
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        id: getFieldKey.rolling_std_overhead,
                        name:"Are you rolling Standard Overhead Costs?",
                        type:"decisive",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            Are you maintaining and updating your standard overhead costs every year?
                            </p>
                            `
                        ,
                        percent:true
                    },
                    {
                        id: getFieldKey.rolling_abc_overhead,
                        name:"Are you rolling Activity Based Overhead Costs?",
                        type:"decisive",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            Are you maintaining and updating your activity based overhead costs every year?
                            </p>
                            `
                        ,
                        percent:true
                    },
                ],
                pageErr:false
            },
            {
                name:"Financing and Loan",
                cmp:false,
                child:[
                    {
                        id: getFieldKey.senior_loan,
                        name:"Ammortization Loans",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            This field represents any major financing loans from any of the various accredited global financial institutions. 
                            These include only large scale amortization loans for assets like plant and land, purchased patents, intellectual property, etc.
                            </p>
                            <p>
                            Note: Only Amortization loans are entered into this field as any borrowing based loans like LOC’s are entered in another field.
                            </p>
                            <p>
                            Note: An amortized loan is a loan where the principal is paid down over time on a series of fixed payments. 
                            Each payment is made up of a portion of interest and a portion of principal.
                            </p>
                        `
                        ,
                        popupName:"How many Senior Debt Loans?",
                        popupArrayName:"Loan Number",
                        fields:[
                            {name:"Name of the Loan",type:"string",required:true},
                            {name:"Total loan amount",type:"Cnumber",required:true,currency:true},
                            {name:"Loan start  date",type:"date",required:true},
                            {name:"% Allocation to Scenario", type:"Cnumber",required:true,percent:true},
                            {name:"Interest Rate ", type:"Cnumber",required:true,percent:true},
                            {name:"Loan duration in years", type:"Cnumber",required:true},
                            {name:"Payments/year", type:"Cnumber",required:true}
                        ],
                        entries:[]
                    },
                    {
                        id: getFieldKey.si_loan,
                        name:"Non Ammortization Loans",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            A simple interest loan is a type of loan where interest is calculated only on the principal balance, 
                            rather than compounding over time. This means that each payment covers part of the principal plus interest accrued since the 
                            last payment.
                            </p>
                        `
                        ,
                        popupName:"How many SI Loan?",
                        popupArrayName:"Loan Number",
                        fields:[
                            {name:"Loan name",type:"string",required:true},
                            {name:"Principal Amount",type:"Cnumber",required:true,currency:true},
                            {name:"Annual Interest rate ", type:"Cnumber",required:true,percent:true}
                        ],
                        entries:[]
                    },
                    {
                        id: getFieldKey.factoring_loan,
                        name:"Factoring Loan",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            Factoring is not a traditional loan but rather a financing method where businesses sell their accounts receivable (invoices) 
                            to a factoring company at a discount in exchange for immediate cash. This is commonly used by businesses with long invoice payment 
                            cycles.
                            </p>
                            <p>
                            Factoring can be recourse (where the business is liable for unpaid invoices) or non-recourse 
                            (where the factor assumes the risk). The cost allocation should be applied based on the amount of 
                            factored receivables related to each product line.
                            </p>
                        `
                        ,
                        popupName:"How many Factored Loan?",
                        popupArrayName:"Loan Number",
                        fields:[
                            {name:"Factored Customer",type:"string",required:true},
                            {name:"Annual Invoiced Amount",type:"Cnumber",required:true,currency:true},
                            {name:"Annual Factoring Receipts", type:"Cnumber",required:true,currency:true}
                        ],
                        entries:[]
                    },
                    {
                        id: getFieldKey.loc_loan,
                        name:"Line of credit Loan",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            A line of credit (LOC) loan provides businesses or individuals with flexible access to funds up to a predetermined limit. 
                            Unlike a term loan, borrowers can withdraw funds as needed and only pay interest on the amount used.
                            </p>
                            <p>
                            There are two main types:
                            </p>
                            <ul>
                                <li>
                                Secured LOC: Backed by collateral such as real estate or inventory.
                                </li>
                                <li>
                                Unsecured LOC: Higher risk and typically comes with higher interest rates.
                                </li>
                            </ul>
                        `
                        ,
                        popupName:"How many LOC Loan?",
                        popupArrayName:"Loan Number",
                        fields:[
                            {name:"Loan name",type:"string",required:true},
                            {name:"Credit Limit",type:"Cnumber",required:true,currency:true},
                            {name:"Average Annual Amount Borrowed",type:"Cnumber",required:true,currency:true},
                            {name:"Annual Interest rate ", type:"Cnumber",required:true,percent:true}                 
                        ],
                        entries:[]
                    },
                    {
                        id: getFieldKey.inv_redadd_loan,
                        name:"Inventory Reduction/Addition",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:`
                            <p>
                            An inventory reduction loan is designed to help businesses liquidate excess inventory or improve cash flow tied up in unsold goods. 
                            This type of loan allows businesses to free up capital for reinvestment in new products, marketing, or other operational needs.
                            </p>
                        `
                        ,
                        popupName:"How many LOC Loan?",
                        popupArrayName:"Loan Number",
                        fields:[
                            {name:"Loan name",type:"string",required:true},
                            {name:"Credit Limit",type:"Cnumber",required:true,currency:true},
                            {name:"Average Annual Amount Borrowed",type:"Cnumber",required:true,currency:true},
                            {name:"WACC/Interest Rate", type:"Cnumber",required:true,percent:true}                 
                        ],
                        entries:[]
                    },
                ],
                pageErr:false
            }
        ]
    }

    createNewCopyOfCalcFields(val:CalculatorType){
        let x = val.map(elem=> ({...elem,child:[...elem.child].map(el=> ({...el, entries:(el.entries ? [...el.entries!!]:[]).map(e=> ([...e].map(e2=> ({...e2})))), secDrop:el.secDrop && {...el.secDrop}   }))}));
        return x;
    }
    getRealTime(){
        let date = new Date().toDateString();
        let time = new Date().toLocaleTimeString();

        return `${date}, ${time}`;
    }
}

export default new Calculator;