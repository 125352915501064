import { useMemo } from 'react'
import OutputScreenV9 from '../components/output/v9/OutputScreen';
import OutputScreenV10 from '../components/output/v10/OutputScreen';
import OutputScreenV11 from '../components/output/v11/OutputScreen';
import OutputScreenV12 from '../components/output/v12/OutputScreen';
import OutputScreen from '../components/output/OutputScreen';

function useOutputByVersion(version:string | undefined) {

    const v = useMemo(()=> parseInt(version?.replaceAll("v","") || "0"),[version]);

    if(v === 12){
        return [OutputScreenV12];
    }
    else if(v === 11){
        return [OutputScreenV11]
    }
    else if(v === 10){
        return [OutputScreenV10]
    }
    else if(v === 9){
        return [OutputScreenV9]
    }
    else{
        return [OutputScreen]
    }
 
}

export default useOutputByVersion
