
type propType={
    className:string,
    onClick:(e:any)=> void,
    myRef:any,
    error:boolean
}
const Question = ({className,onClick,myRef,error}:propType)=>{

    return(
        <div ref={myRef}>
        <svg className={className} onClick={onClick} width="20.271" height="20.271" viewBox="0 0 20.271 20.271">
        <g id="Group_78527" data-name="Group 78527" transform="translate(-1558.815 -772.324)">
            <path id="Path_59258" data-name="Path 59258" d="M17.3,2.969A10.137,10.137,0,0,1,5.019,18.889l-2.967.555A1.049,1.049,0,0,1,.827,18.219l.555-2.967A10.137,10.137,0,0,1,17.3,2.969m1.138,7.168A8.307,8.307,0,1,0,3.116,14.583h0a.909.909,0,0,1,.127.653l0,.009c0,.011,0,.022-.005.033l-.4,2.161,2.149-.4a.912.912,0,0,1,.708.116A8.308,8.308,0,0,0,18.44,10.137" transform="translate(1558.815 772.324)" fill={error ? "whitesmoke" : "#3777bc"} />
            <path id="Path_59259" data-name="Path 59259" d="M426.418,318.7a1.1,1.1,0,0,1-.831-.272.968.968,0,0,1-.259-.695v-.272a.968.968,0,0,1,.259-.695,1.1,1.1,0,0,1,.831-.272,1.123,1.123,0,0,1,.845.272.968.968,0,0,1,.259.695v.272a.968.968,0,0,1-.259.695,1.123,1.123,0,0,1-.845.272m-.845-3.412v-1.533a.452.452,0,0,1,.405-.451q.121-.013.234-.031h.133a2.427,2.427,0,0,0,.883-.333,1.227,1.227,0,0,0,.547-.894,1.932,1.932,0,0,0,.018-.27v-.191a1.143,1.143,0,0,0-.341-.886,1.3,1.3,0,0,0-.913-.313,1.364,1.364,0,0,0-.988.361,1.814,1.814,0,0,0-.379.53.453.453,0,0,1-.582.224l-.62-.255a.453.453,0,0,1-.242-.6,3.731,3.731,0,0,1,.195-.38,2.663,2.663,0,0,1,.6-.7,2.867,2.867,0,0,1,.886-.49,3.575,3.575,0,0,1,1.2-.184,3.742,3.742,0,0,1,1.253.2,2.756,2.756,0,0,1,.954.559,2.5,2.5,0,0,1,.613.865,2.785,2.785,0,0,1,.218,1.117,3.251,3.251,0,0,1-.026.42,2.508,2.508,0,0,1-.171.649,2.586,2.586,0,0,1-.538.817,2.772,2.772,0,0,1-.776.559,3.418,3.418,0,0,1-.9.293v.947a.454.454,0,0,1-.453.453h-.755a.454.454,0,0,1-.453-.453" transform="translate(1142.282 468.674)" fill={error ? "whitesmoke" : "#3777bc"} />
        </g>
        </svg>
        </div>
    )
}

export default Question;