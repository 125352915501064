import { useEffect, useState } from 'react';
import { resultType } from '../../../@types/user';

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import NumberFormat from '../../../utils/NumberFormat';

import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { ExpandOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';


dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);



type loanType = {
    result:resultType,
    setSelectedLoanResult:any,
    myCurrency:string,
    selectedLoan:string,
    setSelectedLoan:(val:string | ((prev:string)=> string) )=> void
}
export default function LoanScreen({result,myCurrency,selectedLoan,setSelectedLoan,setSelectedLoanResult}: loanType) {

    const activeTabSce = useSelector((state: RootState) => state.calculator.activeTabScenarios);

    const [mainLoan,setMainLoan] = useState<{
        sceName:string,
        years:{
            yearName:string,
            interests:any,
            total:number,
            yearConstant : number

        }[],
        nonAmmort:any,
        allJobNames:string[]
    }[]>([]);

    const [nonAmmortLoan,setNonAmmortLoan] = useState([]);

    // Preparing Mappable Array from the Data structure sent by backend
    useEffect(()=>{

        let myMainLoan:{
            sceName:string,
            years:{
                yearName:string,
                interests:any
                total:number,
                yearConstant : number
    
            }[],
            nonAmmort:any,
            allJobNames:string[]
        }[] = [];

        result.forEach((oelem,oind)=>{

            
            let lowestLoanYear = parseInt(dayjs().format("YYYY")) + 10000;
            let highestLoanYear = 0;
            let ob:any = {};

            oelem?.output?.loans?.forEach((elem:any,ind:number) => {
    
                    elem.forEach((ielem:any,iind:number) => {
    
                        ielem.data.forEach((nelem:any,nind:number) => {
    
                            // Finding Highest Loan Year
                            if(nelem.loanYearConstant > highestLoanYear){
                                highestLoanYear = nelem.loanYearConstant;
                            }
    
                            // Finding Lowest Loan Year
                            if(nelem.loanYearConstant < lowestLoanYear){
                                lowestLoanYear = nelem.loanYearConstant;
                            }

                            // Separating loans according to their Loan Year with loan year as the key of the object.
                            if(ob[nelem.loanYearConstant]){

                                if(ob[nelem.loanYearConstant][ielem?.name?.toLowerCase()]){
                                    (ob[nelem.loanYearConstant][ielem?.name?.toLowerCase()].interest += nelem.totalData.interest);
                                    ob[nelem.loanYearConstant][ielem?.name?.toLowerCase()].totalLoanCount++;
                                }
                                else{
                                    ob[nelem.loanYearConstant]={
                                        ...ob[nelem.loanYearConstant],
                                        [ielem?.name?.toLowerCase()] : {
                                            fiscalStart : nelem.fiscalStart,
                                            fiscalEnd : nelem.fiscalEnd,
                                            interest : nelem.totalData.interest,
                                            loanYearConstant : nelem.loanYearConstant,
                                            totalLoanCount : 1
                                        }
                                    };
                                }
                            }
                            else{
                                ob[nelem.loanYearConstant]={
                                    [ielem?.name?.toLowerCase()] : {
                                        fiscalStart : nelem.fiscalStart,
                                        fiscalEnd : nelem.fiscalEnd,
                                        interest : nelem.totalData.interest,
                                        loanYearConstant : nelem.loanYearConstant,
                                        totalLoanCount : 1
                                    }
                                };
                            }
    
                        });
                    });
                    
                });
       
            let currentYear = dayjs().format("YYYY");
            let currDate = parseInt(currentYear);
           
            let newOb;

            // Preparing current year according to fisCal Year
            let obKey = Object.keys(ob)[0];
            if (!obKey){
                newOb =  {
                    yearName : `${currDate}`,
                    interests: {},
                    total : 0,
                    yearConstant : currDate
                }
            }

            let obKey2 = Object.keys(ob[obKey])[0];
            if (!obKey2){
                newOb =  {
                    yearName : `${currDate}`,
                    interests: {},
                    total : 0,
                    yearConstant : currDate
                }
            }

            let fiscStartStr = ob[obKey][obKey2].fiscalStart;
            let fiscStart = dayjs(fiscStartStr,"YYYY-MM-DD");
            let cd = dayjs();
            let cy = currDate;
            if( parseInt(cd.format("MM")) < parseInt(fiscStart.format("MM")) ){
                cy--;
            }
  

            // Transforming Data to the type of mainLoan state variable for each Scenario
         
                let innerKey:any[] = []; 
                if(!ob[cy]){
                
                    newOb =  {
                        yearName : `${cy}`,
                        interests: {},
                        total : 0,
                        yearConstant : cy
                    }
                }

                
                innerKey = Object.keys(ob[cy]);

               
                let tot = 0;
                let inLoans:any = {};
                
                innerKey.forEach((element,index) => {

                    tot += ob[cy][element].interest;
                    inLoans[element] = {
                        value : ob[cy][element].interest,
                        count : ob[cy][element].totalLoanCount
                    }
                });

                newOb =  {
                    yearName : `(${ob[cy][innerKey[0]].fiscalStart}) - (${ob[cy][innerKey[0]].fiscalEnd})`,
                    interests: inLoans,
                    total : parseFloat(tot.toFixed(2)),
                    yearConstant : ob[cy][innerKey[0]].loanYearConstant
                }
           

            console.log("new OB", newOb);

            // Pushing Data for Each Scenario
            myMainLoan.push({
                sceName : oelem.name,
                years : [newOb],
                nonAmmort : oelem.output.nonAmmortLoans,
                allJobNames : activeTabSce.filter(elem=> elem.name === oelem.name )[0].jobs.map(el=> el.name)
            });

        });


        setMainLoan(myMainLoan); // Setting our mainloan state variable
      
    },[]);


    useEffect(()=>{
        console.log("OOb", mainLoan);
    },[mainLoan]);

  

    const loanclickHandler = (sceName:string,loanName:string)=>{

        const selec = result.filter((elem)=> elem.name === sceName );

        setSelectedLoanResult(selec);
        setSelectedLoan(loanName);
    }

    const eachWordUpper = (str:string)=>{
        const arr = str.trim().split(" ");
        return arr.map(elem=> elem.substring(0,1).toUpperCase()+ elem.substring(1)).join(" ");
    }

  return (
    <div>
        {
            mainLoan.map((element,index)=>(
                <div className='mainLoanContainer'>
                    <p className='loanScenarioHeader'>{element.sceName}</p>
                    <div>
                        <div className='mainLoanLeft'>
                            <p>Ammortization Loans</p>
                            <div>
                            {
                                element.years.length > 0 ?
                                element.years.map((elem,ind)=>{

                                    let rowKeys = Object.keys(elem?.interests) || [];

                                    return (<div
                                    key={`mo${ind}`}
                                    >

                                        <TableContainer sx={{padding:2,width:"auto",maxWidth:"500px"}} component={Paper}>
                                            {/* <p></p> */}
                                            <Table aria-label="simple table">
                                            <TableHead>
                                            <TableRow>
                                                <TableCell align="left">{elem.yearName}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left">Loan Type</TableCell>
                                                <TableCell align="right">Total Interest</TableCell>
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {
                                                    rowKeys.map((row,rowInd)=>(
                                                        <TableRow 
                                                        key={`loanRow${rowInd}`}
                                                        className='hoveredRow'
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                                        >
                                                            <TableCell align="left">{eachWordUpper(row)} <div className='loanCatCount'>x{elem.interests[row]?.count || 0}</div></TableCell>
                                                            <TableCell align="right">{elem.interests[row] ? NumberFormat.convertAccordingCurrency(myCurrency,elem.interests[row].value) : 0}</TableCell>
                                                            <TableCell align="center">
                                                                <IconButton 
                                                                sx={{width:25,height:25}}
                                                                onClick={()=> loanclickHandler(element.sceName, row) }
                                                                >
                                                                    <ExpandOutlined style={{fontSize:"16px",fontWeight:"bold"}} />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }

                                                
                                                <TableRow 
                                                sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                                >
                                                    <TableCell align="left">Total Annual Interest</TableCell>
                                                    <TableCell align="right">{NumberFormat.convertAccordingCurrency(myCurrency,elem.total)}</TableCell>
                                                </TableRow>
                                            </TableBody>

                                            </Table>
                                            
                                        </TableContainer>
                                        
                                    </div>)
                                })
                                :
                                <div className='noLoanDataCnt'>
                                    <CreditScoreIcon />
                                    <p>No Loan Data found in <b>{element.sceName} Scenario</b></p>
                                </div>
                            }

                            </div>

                        </div>
                        <div className='mainLoanRight'>
                            <p>Non - Ammortization Loans</p>
                            <div>
                            {
                                element.years.length > 0 ?
                                    <div
                                    style={{
                                        width:"100%"
                                    }}
                                    >

                                        <TableContainer sx={{padding:2,width:600}} component={Paper}>
                                            {/* <p></p> */}
                                            <Table aria-label="simple table">
                                            <TableHead>

                                                <TableRow>
                                                    <TableCell align="left">{element.years[0].yearName}</TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell align="left">Loan Type</TableCell>
                                                    {
                                                        element.allJobNames.map((el,ind)=>(
                                                            <TableCell align="right" key={`jobxyz${ind}`}>{el}</TableCell>
                                                        ))
                                                    }
                                                    <TableCell align="right">Total Interest</TableCell>
                                                </TableRow>
                                                
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    Object.keys(element.nonAmmort).map((rowName:any,rowInd)=>{
                                                        return (
                                                            <TableRow 
                                                            key={`noammort${rowInd}`}
                                                            className='hoveredRow'
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                                            >
                                                                 <TableCell align="left">{rowName} <div className='loanCatCount'>x{element.nonAmmort[rowName].filter((pl:number)=> pl !== 0).length}</div></TableCell>
                                                                {
                                                                    element.allJobNames.map((lr,lrind)=>(
                                                                        <TableCell align="right">
                                                                            {
                                                                            element.nonAmmort[rowName][lrind] ?
                                                                            NumberFormat.convertAccordingCurrency(myCurrency,element.nonAmmort[rowName][lrind])
                                                                            :
                                                                            0
                                                                            }
                                                                        </TableCell>
                                                                    ))
                                                                }
                                                                <TableCell align='right'>
                                                                     {NumberFormat.convertAccordingCurrency(myCurrency,parseFloat((element.nonAmmort[rowName].reduce((a:number,b:number)=> a+b )).toFixed(2)))} 
                                                                </TableCell>
            
                                                            </TableRow>
                                                        )
                                                    })
                                                }

                                                
                                                <TableRow 
                                                sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                                >
                                                    <TableCell align="left">Total Annual Interest</TableCell>
                                                    {
                                                        element.allJobNames.map(()=> <TableCell align="left"></TableCell>)
                                                    }
                                                    <TableCell align="right">{
                                                    NumberFormat.convertAccordingCurrency(myCurrency,(
                                                        Object.keys(element.nonAmmort)
                                                        .map((elem,ind)=> element.nonAmmort[elem].reduce((a:number,b:number)=> a+b ) )
                                                        .reduce((a:number,b:number)=> a+b)
                                                    ))
                                                    }</TableCell>
                                                </TableRow>
                                            </TableBody>

                                            </Table>
                                            
                                        </TableContainer>
                                        
                                    </div>
                               
                                :
                                <div className='noLoanDataCnt'>
                                    <CreditScoreIcon />
                                    <p>No Loan Data found in <b>{element.sceName} Scenario</b></p>
                                </div>
                            
                            }

                            </div>

                        </div>
                    </div>

                </div>
            ))
        }
      
    </div>
  )
}
