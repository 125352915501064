const keys = {
    project_description: "5G8Y4K6B2T13270",
    plant_number: "8K0K0W3R1H61160",
    no_of_parts: "4B5X0N5B3D47429",
    symmetrical: "7L1L4R9P7M07455",

    sell_price: "8W6J9Z8L4J73836",
    bom_price : "8U3B9V7T8F53553",
    volume: "5I8W7S1P5N00442",
    upper_capacity: "6E3O5V5G5J97679",
    lower_capacity : "7W0U6S0X2C8803",
    months_remaining : "7U5R2Y5Q7A35421",
    cost_of_capital : "2O6Z5U6S1I30351",
    tax_rate : "6T9W4C9Y6F57683",
    profit_loss: "6Y6R1F6N1M39803",
    inflation : "9I8S2B1K0M98938",
    irr_hurdle: "1G4S8M9J6I65614",
    atros_rate: "7B7P9O6I8E86834",
    scrap_sales: "1G8P6X8Y2H98725",
    beginning_fiscal: "6G5S1N3F1I73313",

    overhead_rate: "6F6G1W7Y5L1704",
    machine_burden_rate: "3W5X8Q8T0N18876",
    sga_marketing_cost: "5E0E2D8C6B53829",

    operator_continuous: "4Z8S1Z2V7E89694",
    operator_nc : "7H0M6Q2E5S18811",
    break_relief : "4X3J7O6H3U33888",
    total_break: "8L9O7V7E8A01502",
    base_wage: "4D2P5Y1F3M63444",
    benefit_ratio: "7B4W5S1K7H63876",
    fringed_wage: "1V8T4M7B9V09606",
    otp_perc: "3S7E0W4G3B04017",
    abs_and_turn: "9A6N4M2P1O77743",

    abc_operator_continuous: "1U5T4L1K1H7498",
    abc_operator_nc : "6Y8Z5W5K8D46519",
    abc_break_relief : "9X9O0R1Y6G46593",
    abc_total_break: "7Q5L4S7B0H57281",
    abc_base_wage: "3O5E9A9M2R22359",
    abc_benefit_ratio: "2O1A9Y0D9S90049",
    abc_fringed_wage: "2Q3B6B0W9U13013",
    abc_otp_perc: "2H3W2G5P6E03439",
    abc_abs_and_turn: "6Q6I3B5J8L82258",

    downtime: "1X4E0K7O5Y65303",
    oee: "2Z6O0Y3G0A81499",
    scrap: "8X0P3Y2Q9B8636",
    cycle_time: "8J9Q6C0A2H81203",
    shifts_day: "2J0S4G1T2Z02140",
    hours_shift: "6Y1U0R0H9M64146",
    days_week: "3D6F4I3Z4X42702",
    days_year: "4L3L8K5B9D59354",
    otp1: "6G7Z9L7J9J09362",
    otp2: "6S2J6I1U0K13749",

    vl_added: "9D9G9W1L5F29757",
    vl_removed: "6L4A7R8Y3F90095",
    fl_added: "7Z2Q2A3R9J54555",
    fl_removed: "4K4B9U4K8U79094",
    alloc_shared: "7S4R4N3P0T29742",
    sga_added: "6H8Q5G2E3N76567",
    sga_removed: "3S7A4A2Y9J53333",
    utility_inc_red: "4E6N1Z3W3Z85689",
    annual_plant_rent: "7O3W0E1V2Q654",
    ong_annual_testing: "3J1E4V8E1Q44187",
    add_activity_line_added: "2B6Y3S9Z4Q39230",
    other_var_line_added: "7T4T0Z8H5A30653",
    
    annual_sga_allocation: "1Z9D0Q8T6M40815",
    legal_pro_service: "1U2Y8L3J5X49805",
    tech_service: "9H5H9J5S6H77892",
    annual_marketing_cost: "5L6Q4F9A6M65478",
    rnd: "8J9B9R0Z9C98276",
    launch_cost: "1C3L5J8G3J67481",
    design_and_engineer_cost: "6C8W1G2X2F47565",

    project_inv: "2Y6K7Z5E8K93714",
    capital_inv: "5F7W3M3L7I06607",
    installation_and_rigging: "9J0S1L1R0Z42970",
    any_other_expense: "3I2W1O0J0G14350",

    outbound_pre_fright: "9I7R2I4V9M98849",
    inbound_pre_fright: "5H3G1T3Y7G35181",
    quality_charge: "3N3A9F0R9M7789",
    tp_sorting: "1X7K5E3W6L13622",
    internal_sorting: " 1Y8H7F4M4R44472",
    offline_charge: " 7E5K8A0P2S76616",
    shut_down_charge: "4Z4P6M1G3J23511",
    any_other_offstd_cost: "9C2I5V4Z3F10343",
    rolling_std_overhead: "3E0F0U7I1O70619",
    rolling_abc_overhead: "3W4S9U3W1M69278",

    senior_loan: "8L6T6Y6Q4X0017",
    equipment_loan: "1M1H3F5W3I82252",
    mezzanine_loan: "2D8S3V6C3C95849",
    micro_loan: "1N2M9R2K3B29182",
    personal_loan: "9W4K8P5D6T73199",
    real_estate_loan: "5A4I6S7X7H4417",
    sba_loan: "4I2D1K1G0K76728",
    factoring_loan: "1O8W2E9S6P1410",
    loc_loan: "6C1P7K2Q2W13415",
    si_loan: "6R4K3H4G1L30618",
    inv_redadd_loan: "1G7Q8F6D5N10606"



}

export default keys;