import { Modal } from "@mui/material";
import { newFormInpPropType } from "../../@types/user";
import { useEffect, useRef, useState } from "react";
import { CloseOutlined, RightOutlined } from "@ant-design/icons";
import InputBox from "../input";
import { Alert, Button } from "antd";
import Question from "../../svg/question";
import { useDispatch } from "react-redux";
import { doublePopUpFirstSubmit, doublePopUpInnerInpChange, doublePopUpSecSubmit } from "../../redux/calculatorSlice";
import ModalBox from "../modal";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import HintGuide from "../guide/HintGuide";
const DoublePopup = (props:newFormInpPropType)=>{

    const version = useSelector((state:RootState)=> (state.calculator.tabs[state.calculator.activeTab].version) );

    const [showMsg,setShowMsg] = useState<boolean>(false);
    const msgCnt = useRef<any>();
    const msgRef = useRef<any>();

    const [showPopup,setPopUp] = useState<boolean>(false);
    const [showPopup2,setPopUp2] = useState(false);
    const [lastInvValue,setLastInvValue] = useState<number>(props.data?.value ? parseInt(props.data?.value ) : 0);
    const [inpValue,setInpValue] = useState<string>(props.data?.value || "");
    const [error,setError] = useState("");

    const dispatch = useDispatch();

    const clickFunc = (e:any)=>{
        setPopUp(true)

    }

    useEffect(()=>{
        setLastInvValue(props.data?.value ? parseInt(props.data?.value ) : 0);
        setInpValue(props.data?.value || "");
    },[props.data?.value]);

    useEffect(()=>{

        window.addEventListener('click',windowClickFunction);

        return ()=>{
            window.removeEventListener('click',windowClickFunction)
        }

      },[]);

      const windowClickFunction = (e:any)=>{
  
        if(msgCnt.current && msgRef.current){

            if (!msgCnt.current.contains(e.target) && !msgRef.current.contains(e.target)){
                setShowMsg(false);
            }

        }
  
      }

      const closeFirstModal = ()=>{
        setInpValue(props.data?.value || "")
        setPopUp(false);
      }

      const submitFirstModal = ()=>{
        
            let ret = dispatch(doublePopUpFirstSubmit({
                value : inpValue,
                outerIndex: props.outerIndex,
                innerIndex : props.index,
                lastInpValue : lastInvValue
            }))

            let retOb = JSON.parse(ret.payload.value);

            if(retOb.error){
                setError(retOb.error);
                return;
            }

            setPopUp2(retOb.popup2);
            setLastInvValue(retOb.lastInpvalue);
            setInpValue(retOb.inpValue);
        

        setPopUp(false);
      }

      const verifySecondModal = ()=>{

        let errorFound = false;
        props.data?.entries?.forEach(outerElem=>{
            outerElem.forEach(innerElem => {
                if(!innerElem.value && innerElem.required){
                    errorFound = true;
                }
            });
        })

        dispatch(doublePopUpSecSubmit({
            value:JSON.stringify({error:errorFound}),
            outerIndex : props.outerIndex,
            innerIndex : props.index
        }))
     
    


        setPopUp2(false);
        
      }

    return(
        <>

        <Modal
            className="modal"
            open={showPopup2}
            onClose={verifySecondModal}
            >
                <div className="modalPopUpGrid">

                <CloseOutlined
                className="popupClose" 
                onClick={verifySecondModal}
                />
                    <div className="mpugTop">

             
                        {
                            props.data?.entries?.map((elem,index)=>(
                                <div key={index}>
                                    <h4 style={{margin:"10px 0",color:"#24336D"}}>
                                        {
                                           `${props.data?.popupArrayName} ${index+1}`
                                        }
                                        </h4>
                                    {
                                        elem.map((ielem,iindex)=>(
                                            <InputBox
                                            key={`${index}${iindex}`}
                                            type={ielem.type}
                                            placeholder={ielem.name}
                                            value={ielem.value}
                                            currency={ielem.currency}
                                            percent={ielem.percent}
                                            onChange={(e:any)=>{
                                                
                                                dispatch(doublePopUpInnerInpChange({
                                                    value:JSON.stringify({
                                                        index,
                                                        iindex,
                                                        data:e.target.value
                                                    }),
                                                    outerIndex : props.outerIndex,
                                                    innerIndex : props.index
                                                }))


                                            }}
                                            />
                                        ))
                                    }
                                </div>
                            ))
                        }                    
              

                    </div>

                    <div className="mpugBot">

                    <Button className="mybtn-blue" type="text" onClick={verifySecondModal}>
                        Done <RightOutlined />
                    </Button>

                    </div>

                </div>           

            </Modal>

        <Modal
            className="modal"
            open={showPopup}
            onClose={closeFirstModal}
            >
            <div className="modalPopUp">

                <CloseOutlined
                className="popupClose"
                onClick={closeFirstModal}
                 />
                    <div className="mpuTop">

                    <div>
                            <InputBox
                            type="Cnumber"
                            placeholder={props.data?.popupName || ""}
                            value={inpValue}
                            onChange={(e)=> {
                                setInpValue(e.target.value);
                            }}
                                />
                    </div>

                    </div>
                  
                  <div className="mpuBot">
                    <Button className="mybtn-blue" type="text" 
                    onClick={submitFirstModal}
                        >
                        Done <RightOutlined />
                    </Button>
                  </div>

                </div>

            </Modal>

            {
                error &&
                <ModalBox 
                header="Alert"
                message={error}
                open={error ? true : false}
                onClose={()=> setError("")}
                onOk={()=> setError("")}
                />
            }


            <div className="formMainCnt">
                <p className="formTitle">{props.data?.name}</p>
                <div className={
                    `
                    formInputCnt 
                    ${(props.data?.value && props.data?.error) && "doublePopupempty"} 
                    ${(props.data?.value && !props.data?.error) && "doublePopupfilled"}
                    ` 
                    }>

                    <div
                    className="mainInput myselect"
                    style={{height:"100%",flex:"1",cursor:"pointer",display:"flex",alignItems:"center",color:"gray"}}
                    onClick={clickFunc}
                    >Click to edit</div>

               
                    <Question className="formQuestion" onClick={()=> setShowMsg(true)}
                    myRef={msgRef} error={props.data?.error || false} />
                </div>

                {
                    props.data?.error &&
                    <Alert 
                    style={{fontSize:"12px",padding:"5px 10px"}} 
                    description="One or more sub-fields are Empty!" 
                    type="error" 
                    showIcon={false} />
                }
                 {
                    showMsg &&
                    <>
                    {
                        parseInt(version?.replaceAll("v","") || "0") > 10 ?
                        <HintGuide 
                        onClose={()=> setShowMsg(false)}
                        targetElem={msgRef?.current}
                        heading={props.data?.name}
                        msg={props.data?.msg}
                        />
                        :
                        <div className="formMsg" ref={msgCnt}>
                        {

                            parseInt(version?.replaceAll("v","") || "0") >= 3 ?
                            (
                                (props.data && props.data.msg) &&
                                props.data.msg.map((elem:any,index:number)=>(
                                    <p key={`msg${index}`}>{elem}</p>
                                )) 
                            )
                            :
                            <p>{props.data?.msg}</p>
                        }
                        </div>

                    }
                    </>
                }
            </div>
        </>
    )

}

export default DoublePopup;