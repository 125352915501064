import {useContext,useEffect,useState} from "react";
import Header from "../components/Header";
import { CalcHistoryType, GlobalContextType, scenarioType, singleTabType } from '../@types/user';
import { useNavigate } from "react-router-dom";
import { Button, Input, Spin } from "antd";
import { globalData } from "../context/globalContext";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Axios } from "../Axios";
import AuthenticatedLayout from "../components/layouts/AuthenticatedLayout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import PasswordGenerator from "../utils/PasswordGenerator";
import Calculator from "../utils/Calculator";
import { newTabwithCustomData } from "../redux/calculatorSlice";
import { IconButton } from "@mui/material";
import { Search } from "@mui/icons-material";
import ModalBox from "../components/modal";
import { VERSION } from "../config";
import FSLoader from "../components/FSLoader";
import ModalBox2 from "../components/modal/ModalBox2";

const Histories = ()=>{

    const navigator = useNavigate();
    const { activeTab, setActiveTab } = useContext(globalData) as GlobalContextType;
    const user = useSelector((state: RootState) => state.user.user );
    const [allowView,setAllowView] = useState<boolean>(false);
    const [loading,setLoading] = useState<boolean>(false)
    const [prevLoad,setPrevLoad] = useState<boolean>(false)
    const [nextLoad,setNextLoad] = useState<boolean>(false)
    const [data,setData] = useState<CalcHistoryType[]>([]);
    const [next,setNext] = useState<boolean>(false);
    const [prev,setPrev] = useState<boolean>(false);
    const [page,setPage] = useState<number>(1);
    const [search,setSearch] = useState<string>("");
    const [timeLimit, setTimeLimit] = useState<any>();
    const [toBeOpen,setToBeOpen] = useState<null | {latestVersion:string,actualVersion:string,index:number}>(null);
    const dispatch = useDispatch();


    useEffect(()=>{

        ApiCall();

    },[page])

    const ApiCall = ()=>{
        setLoading(true);

        Axios.get(`/api/v1/calculator?page=${page}&search=${search ? search : ""}`,{
            headers:{
                Authorization:user?.token
            }
        })
        .then(result=>{
            setAllowView(true);

            setLoading(false);
            setPrevLoad(false);
            setNextLoad(false);
            let x = [...result.data.data.history];
            setNext(result.data.data.next);
            setPrev(result.data.data.prev);
            setData(x);
            
            
        })
        .catch(err=>{
            setAllowView(true);

            setLoading(false);
            setPrevLoad(false);
            setNextLoad(false);
            
        })
    }

    useEffect(()=>{

        clearTimeout(timeLimit);
        setTimeLimit(setTimeout(()=>{

            ApiCall();

        },500))

    },[search]);


    const isDefaultScenarioPresent = (arr:scenarioType[],name:string)=>{

        let ind = 0;
        while(ind < arr.length){

            if( arr[ind].name.toLowerCase() === name.toLowerCase() ){
                 return true;
            }
            
            ind++;
        }

        return false;

    } 


    const confirmOpen = (index:number)=>{

        let ob:any = data[index];

        console.log("ob",ob);
        console.log("version",VERSION);

        if(ob.version === VERSION){
            openCalc(index);
        }
        else{

            setToBeOpen({
                index:index,
                actualVersion: ob.version,
                latestVersion : VERSION
            });

        }
    }

   const openCalc = (index:number,type:string = "old")=>{

        let ob:any = data[index];

        console.log("ob",ob);
        let insertable:any;

        if(type === "new"){

            setLoading(true);
            
            insertable =  ob.scenario.map((element:any,index:number)=>{
                return ({
                    id:element._id,
                    key:PasswordGenerator.getUniqid(12,6),
                    name:element.name,
                    jobs:element.job.map((elem:any,ind:number)=>{

                        let newC = Calculator.getCalculatorfields({type:element.type});
                        let oldData = JSON.parse(elem.data);

                        // Replacing new common index with old one
                        for(let i=0; i<newC.length; i++){
                            for(let j=0; j<oldData.length; j++){
                                if(newC[i].name === oldData[j].name){

                                    for(let k=0; k<newC[i].child.length; k++){

                                        for(let l=0; l<oldData[j].child.length; l++){

                                            if(newC[i].child[k].name === oldData[j].child[l].name){

                                                newC[i].child[k] = oldData[j].child[l];
                                                break;
                                            }
                                        
                                        }
                                    }

                                    break;
                                }
                            }
                        }


                        // Checking for validaton after updating fields
                        for(let i=0; i<newC.length; i++){
                        
                            let errFound = false;
                            let y = newC[i].child;

            
                            y.forEach(element => {

                                if(element.type !== "DoublePopup" && element.type !== "popup" && element.type !== "oneScreenPopup"){
                                    if(element.required && !element.value){
                                        element.error = true
                                        errFound = true;
                                    }
                                    else{
                                        element.error = false
                                    }
                                }
                                else if(element.type === "DoublePopup"){
                                    if(element.value){
                                        element.entries?.forEach(outerelem => {
                                            outerelem.forEach(innerElem => {
                                                if(!innerElem.value && innerElem.required){
                                                    element.error = true
                                                    errFound = true;
                                                }
                                                else{
                                                    element.error = false
                                                }
                                                
                                            });
                                        });
                                    }
                                }
                                else if(element.type === "oneScreenPopup"){
            
                                    element.dataArr?.forEach(elem => {
            
                                        elem.data.forEach(ielem => {
            
                                            if(ielem.required && !ielem.value){
                                                element.error = true
                                                errFound = true;
                                            }
                                            else{
                                                element.error = false
                                            }
                                            
                                        });
                                        // if(!elem.value){
                                        //     element.error = true
                                        //     errFound = true;
                                        // }
                                        // else{
                                        //     element.error = false
                                        // }
                                });
            
                                }
                                else{
                                    element.data?.forEach(elem => {
                                            if(!elem.value){
                                                element.error = true
                                                errFound = true;
                                            }
                                            else{
                                                element.error = false
                                            }
                                    });
                                }
                            });
            
                            if(errFound){
                                newC[i].pageErr = true;
                                newC[i].cmp = false;
                            }
                            else{
                                newC[i].pageErr = false;
                                newC[i].cmp = true;
                            }
                            
                        }

                        return ({
                            id:elem._id,
                            key:PasswordGenerator.getUniqid(),
                            name:elem.name,
                            data:[ newC ],
                            activeIndex : 0,
                            type:0
                        })
                    }),
                    currency:element.currency,
                    activeJob:0,
                    type:element.type
                });       

            }).sort((a:any,b:any)=> a.type - b.type );

            let uploadable = insertable.map((element:any,index:number)=>{
                return ({
                    ...element,
                    jobs:element.jobs.map((elem:any,ind:number)=>{

                        return ({
                            ...elem,
                            data: JSON.stringify(elem.data[0]),
                     
                        })
                    }),
              
                });       

            });

            console.log("uploadable", uploadable);

            Axios.patch(`/api/v1/calculator`,{
                tabId : ob._id,
                scenarios : uploadable,
                version : VERSION
            },{
                headers:{
                    Authorization: user?.token
                }
            })
            .then((result)=>{

                let x:singleTabType = {
                    id:ob._id,
                    version: type === "new" ? VERSION : ob.version,
                    key:PasswordGenerator.getUniqid(16,8),
                    tabName:ob.name,
                    activeScenario:0,
                    showResult:false,
                    output:[],
                    scenario: insertable
                }

                dispatch( newTabwithCustomData(x) );
                setActiveTab(0); // For styling home tab as active
                navigator("/home"); // for navigating to home page


            })
            .catch(err=>{

                console.log("update err", err);
            })
            .finally(()=>{
                setLoading(false);
            })

        }
        else{

            insertable = ob.scenario.map((element:any,index:number)=>(

                {
                    id:element._id,
                    key:PasswordGenerator.getUniqid(12,6),
                    name:element.name,
                    jobs:element.job.map((elem:any,ind:number)=>(
                        {
                            id:elem._id,
                            key:PasswordGenerator.getUniqid(),
                            name:elem.name,
                            data:[ JSON.parse(elem.data) ],
                            activeIndex : 0,
                            type:0
                        }
                    )),
                    currency:element.currency,
                    activeJob:0,
                    type:element.type
                }
            )).sort((a:any,b:any)=> a.type - b.type )

            let x:singleTabType = {
                id:ob._id,
                version: type === "new" ? VERSION : ob.version,
                key:PasswordGenerator.getUniqid(16,8),
                tabName:ob.name,
                activeScenario:0,
                showResult:false,
                output:[],
                scenario: insertable
            }

            console.log("old data", x);

            dispatch( newTabwithCustomData(x) );
            setActiveTab(0); // For styling home tab as active
            navigator("/home"); // for navigating to home page
        }

   }


    return(
        <>
        <AuthenticatedLayout>
        {
            allowView &&
            <>
            {
                loading &&
                <FSLoader>
                    <p className="loaderTxt">Loading...</p>
                </FSLoader>
            }
            <div id="main" style={{paddingTop: "77px"}}>
                <Header />   

                <div style={{
                    padding:"100px 0 50px 0",
                    margin:"0 auto",
                    maxWidth:"800px",
                    width:"90%",
                    }}>

                    <div style={{
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"flex-start",
                        height:"48px",
                        margin:"0 auto 15px auto"
                        }}>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "25px",
                            border: "1px solid #3777BC",
                            overflow: "hidden",
                            height:"48px",
                            width:"100%",
                            maxWidth:"400px",
                            background:"white",
                            padding:"0 5px"
                        }}>
                            <Input 
                            type="search" 
                            style={{
                                flex:"1",
                                height:"100%"
                            }} 
                            placeholder="Search..."
                            value={search}
                            bordered={false}
                            onChange={(e)=> {
                                setSearch(e.target.value ? e.target.value : "")
                            }}
                            />

                            <IconButton size="medium" onClick={ApiCall}>
                                <Search />
                            </IconButton>

                        </div>
                    
          
                        {
                            loading &&
                            <Spin style={{margin:"0 10px"}} />
                        }
                    </div>
                    
                    <div className="myTableCnt">
                    <table className="myTable">
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Name</th>
                            <th>Version</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            data.length > 0 ? 
                            data.map((elem,index)=>(
                                <tr key={index}>
                                    <td>
                                        {
                                            <>
                                            {new Date(elem.createdAt).toDateString()} {new Date(elem.createdAt).toLocaleTimeString()}
                                            </>
                                        }
                                    </td>
                                    <td>
                                        {elem.name}
                                    </td>
                                    <td>
                                        {elem.version}
                                    </td>
                                    <td>
                                        <Button 
                                        className="histbtn-blue"
                                         type="text"
                                         style={{padding:"5px 5px !important"}}
                                          onClick={()=> confirmOpen(index)}>
                                            Open    
                                        </Button>
                                    </td>
                                </tr>
                            ))
                            :
                            <tr>
                                <td colSpan={4} style={{padding:"50px 10px"}}>
                                No data
                                </td>
                            </tr>
                        }
                        </tbody>
                    </table>
                    </div>

                    <div style={{
                        margin:"50px 0 20px 0",
                        display:"flex",
                        justifyContent:"flex-end"
                    }}>
                        <Button 
                        disabled={!prev} 
                        className="histbtn-gray"
                        type="text" 
                        size="large" 
                        style={{margin:"0 5px"}}
                        onClick={()=> {setPage(prev=> prev-1); setPrevLoad(true)} }
                        loading={prevLoad}
                        >
                            <LeftOutlined />
                            Back
                        </Button>
                        <Button 
                        disabled={!next} 
                        className="histbtn-blue bk-blue"
                        type="text" 
                        size="large" 
                        style={{margin:"0 5px"}}
                        onClick={()=> {setPage(prev=> prev+1); setNextLoad(true) }}
                        loading={nextLoad}
                        >
                            Next
                            <RightOutlined />
                        </Button>
                    </div>
                
                </div>

            </div> 


            <footer id="footer">
                    &copy; Cornerstone Consulting Organizations
            </footer>
            {/* <ModalBox
            header="Confirmation"
            message={`Your calculation is of older version "${toBeOpen?.actualVersion}". 
            Current Version is "${toBeOpen?.latestVersion}".\n 
            Press "OK" to Open as new version.
            Press "Cancel" to open as old version`}
            open={toBeOpen !== null}
            onClose={()=> setToBeOpen(null)}
            onOk={()=>{
                openCalc(toBeOpen!.index,"new")
            }}
            onCancel={()=>{
                openCalc(toBeOpen!.index,"old")
            }}
            /> */}
            <ModalBox2
            header="Confirmation"
            message={<p>Your calculation is of older Version "{toBeOpen?.actualVersion}". Current Version is "{toBeOpen?.latestVersion}"</p>}
            open={toBeOpen !== null}
            onClose={()=> setToBeOpen(null)}
            buttons={[
                <button 
                className={`modalBtn`} 
                onClick={()=> openCalc(toBeOpen!.index,"old") }>
                    open as old
                </button>,
                 <button 
                 className={`modalBtn`} 
                 onClick={()=> openCalc(toBeOpen!.index,"new") }>
                    open as new
                 </button>
            ]}
            />
            </>
        }       
        </AuthenticatedLayout> 
        </>
    )

}

export default Histories;